import React from "react"
import {
  Main,
  LeftSide,
  RightSide,
  Wrapper,
  ButtonMain,
  ButtonWrapper,
  GoldenButton,
  ArrowBox,
} from "./style"
import image1 from "../../../../images/hire-description-image1.jpg"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import arrow from "../../../../images/arrow.png"
import { Link } from "gatsby-plugin-react-intl"
import { useIntl, navigate } from "gatsby-plugin-react-intl"

const DescriptionComponent = ({ field1, button1, button2 }) => {
  const intl = useIntl()
  const lang = intl.locale
  return (
    <>
      <ArrowBox>
        <img src={arrow} alt="arrow" />
      </ArrowBox>

      <Main>
        <Wrapper>
          <LeftSide imageFirst={true}>
            {field1 && renderRichText(field1)}
          </LeftSide>

          <RightSide imageFirst={true} image={image1} />
        </Wrapper>
      </Main>

      <ButtonMain>
        <ButtonWrapper>
          <Link to={lang === "pl" ? "/wyszukiwarka/" : "/serach/"}>
            <GoldenButton>{button1}</GoldenButton>
          </Link>
          <Link to={lang === "pl" ? "/kontakt#form" : "/contact#form"}>
            <GoldenButton>{button2}</GoldenButton>
          </Link>
        </ButtonWrapper>
      </ButtonMain>
    </>
  )
}

export default DescriptionComponent
